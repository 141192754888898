import { ApolloError } from "@apollo/client";
import { Button, Drawer, Error, Loading, TabPanel, Tabs } from "components";
import { useUpdateAssignedNotifications } from "hooks";
import React, { FC, useCallback, useMemo, useState } from "react";
import { NOTIFICATION_CATEGORY, NOTIFICATION_STATUS, NotificationAssignment } from "types/notifications";
import { NOTIFICATIONS_TAB, NOTIFICATIONS_TABS } from "types/tabs";
import { NotificationsList } from "./components/NotificationsList";
import { NotificationsPaginationTabs } from "../notificationsInfo/useNotificationsInfo";
import { Chip } from "@mui/material";
import { useAuthContext } from "contexts";

type NotificationsSidebarProps = {
  handleClose: () => void;
  notificationsAll: NotificationAssignment[];
  notificationsEmployeeAndProjects: NotificationAssignment[];
  notificationsTimesheets: NotificationAssignment[];
  notificationsProcesses: NotificationAssignment[];
  changePage: (tab: NOTIFICATIONS_TAB, page: number) => void;
  paginationAll: NotificationsPaginationTabs;
  paginationEmployeeAndProjects: NotificationsPaginationTabs;
  paginationTimesheets: NotificationsPaginationTabs;
  paginationProcesses: NotificationsPaginationTabs;
  counterEmployeeAndProjects: number;
  counterTimesheets: number;
  counterProcesses: number;
  counterAll: number;
  loading: boolean;
  error: ApolloError | undefined;
};

export const NotificationsSidebar: FC<NotificationsSidebarProps> = ({
  handleClose,
  notificationsAll,
  notificationsEmployeeAndProjects,
  notificationsTimesheets,
  notificationsProcesses,
  changePage,
  paginationAll,
  paginationEmployeeAndProjects,
  paginationTimesheets,
  paginationProcesses,
  counterEmployeeAndProjects,
  counterTimesheets,
  counterProcesses,
  counterAll,
  loading,
  error,
}) => {
  const { isManager } = useAuthContext();
  const { updateAssignedNotifications } = useUpdateAssignedNotifications(true);

  const notificationTabs = useMemo(() => {
    if (isManager()) {
      return NOTIFICATIONS_TABS;
    }
    return NOTIFICATIONS_TABS.filter(tab => [
      NOTIFICATIONS_TAB.TIMESHEETS,
      NOTIFICATIONS_TAB.ALL,
    ].includes(tab.key));
  }, [isManager]);

  const [activeTab, setActiveTab] = useState<NOTIFICATIONS_TAB>(
    isManager() ? NOTIFICATIONS_TAB.EMPLOYEE_AND_PROJECTS : NOTIFICATIONS_TAB.TIMESHEETS
  );

  const openedNotifications = useMemo(() => {
    if (activeTab === NOTIFICATIONS_TAB.EMPLOYEE_AND_PROJECTS) {
      return notificationsEmployeeAndProjects;
    }
    if (activeTab === NOTIFICATIONS_TAB.TIMESHEETS) {
      return notificationsTimesheets;
    }
    if (activeTab === NOTIFICATIONS_TAB.PROCESSES) {
      return notificationsProcesses
    }
    return [];
  }, [activeTab, notificationsEmployeeAndProjects, notificationsTimesheets, notificationsProcesses])

  const activeCategories = useMemo(() => {
    if (activeTab === NOTIFICATIONS_TAB.EMPLOYEE_AND_PROJECTS) {
      return [NOTIFICATION_CATEGORY.PROJECTS, NOTIFICATION_CATEGORY.EMPLOYEES];
    }
    if (activeTab === NOTIFICATIONS_TAB.TIMESHEETS) {
      return [NOTIFICATION_CATEGORY.TIMESHEETS];
    }
    if (activeTab === NOTIFICATIONS_TAB.PROCESSES) {
      return [NOTIFICATION_CATEGORY.PROCESSES];
    }
    return [];
  }, [activeTab])

  const markNotificationsAsRead = useCallback(async () => {
    await updateAssignedNotifications({
      categories: activeCategories,
      status: NOTIFICATION_STATUS.READ,
    });
  }, [updateAssignedNotifications, activeCategories]);

  const tabs = notificationTabs.map((tab) => {
    const counter =
      tab.key === NOTIFICATIONS_TAB.EMPLOYEE_AND_PROJECTS
        ? counterEmployeeAndProjects
      : tab.key === NOTIFICATIONS_TAB.TIMESHEETS
        ? counterTimesheets
      : tab.key === NOTIFICATIONS_TAB.PROCESSES
        ? counterProcesses
      : counterAll
    return {
      ...tab,
      adornment: tab.key !== NOTIFICATIONS_TAB.ALL && (
        <Chip size="small" className="px-1 text-xs" label={counter} />
      ),
    };
  });

  return (
    <Drawer isOpen onClose={handleClose} title="Notifications">
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        withSearchParams={false}
        classes={{
          root: "gap-5 sm:gap-5"
        }}
      />
      <div className="flex h-full flex-col items-end justify-center">
        {error ? (
          <Error />
        ) : loading ? (
          <Loading />
        ) : (
          <>
            <TabPanel
              activeTab={activeTab}
              className="flex h-full w-full flex-col items-center px-0 md:pt-5"
            >
              {activeTab === NOTIFICATIONS_TAB.EMPLOYEE_AND_PROJECTS && (
                <NotificationsList
                  notifications={openedNotifications}
                  onItemClick={handleClose}
                  pagination={paginationEmployeeAndProjects}
                  changePage={(page) =>
                    changePage(NOTIFICATIONS_TAB.EMPLOYEE_AND_PROJECTS, page)
                  }
                />
              )}
              {activeTab === NOTIFICATIONS_TAB.TIMESHEETS && (
                <NotificationsList
                  notifications={openedNotifications}
                  onItemClick={handleClose}
                  pagination={paginationTimesheets}
                  changePage={(page) =>
                    changePage(NOTIFICATIONS_TAB.TIMESHEETS, page)
                  }
                />
              )}
              {activeTab === NOTIFICATIONS_TAB.PROCESSES && (
                <NotificationsList
                  notifications={openedNotifications}
                  onItemClick={handleClose}
                  pagination={paginationProcesses}
                  changePage={(page) =>
                    changePage(NOTIFICATIONS_TAB.PROCESSES, page)
                  }
                />
              )}
              {activeTab === NOTIFICATIONS_TAB.ALL && (
                <NotificationsList
                  notifications={notificationsAll}
                  onItemClick={handleClose}
                  pagination={paginationAll}
                  changePage={(page) => changePage(NOTIFICATIONS_TAB.ALL, page)}
                />
              )}
            </TabPanel>

            <div className="flex justify-end gap-2">
              <Button
                variant="primary"
                data-cy="cancel-button"
                onClick={handleClose}
              >
                Close
              </Button>
              {activeTab !== NOTIFICATIONS_TAB.ALL && (
                <Button
                  variant="secondary"
                  onClick={markNotificationsAsRead}
                  data-cy="mark-as-read-button"
                  disabled={
                    openedNotifications && openedNotifications.length === 0
                  }
                >
                  Mark all as read
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
};
